import React, { useEffect, useState } from "react"
import { GetEndpoints } from "../../Services/Account";

const GenerateMain: React.FunctionComponent<{}> = (props) => {

    const [storedPlatforms, setStoredPlatforms] = useState(JSON.parse(localStorage.getItem('get-platforms') || '{}'));

    const [descriptionEndpoints, setDescriptionEndpoints] = useState(null);
    const [titleEndpoints, setTitleEndpoints] = useState(null);
    const [coraGPTEndpoints, setCoraGPTEndpoints] = useState(null);
    const [roomStagingEndpoints, setRoomStagingEndpoints] = useState(null);
    const [roomRemodelingEndpoints, setRoomRemodelingEndpoints] = useState(null);
    const [roomGeneratgionEndpoints, setRoomGenerationEndpoints] = useState(null);
    const [listingVideoEndpoints, setListingVideoEndpoints] = useState(null);
    const [AgentVideoEndpoints, setAgentVideoEndpoints] = useState(null);
    const [ListingGenerationEndpoints, setListingGenerationEndpoints] = useState(null);
    const [leaseAgreementEndpoints, setLeaseAgreementEndpoints] = useState(null);
    const [generateQRCodeEndpoints, setGenerateQRCodeEndpoints] = useState(null);
    const [floorPlanEndpoints, setFloorPlanEndpoints] = useState(null);
    const [generatePostEndpoints, setGeneratePostEndpoints] = useState(null);
    const [styleTransferEndpoints, setStyleTransferEndpoints] =useState(null);

    useEffect(() => {
        fetchEndpoints("Description Generation", setDescriptionEndpoints);
        fetchEndpoints("Title Generation", setTitleEndpoints);
        fetchEndpoints("CoraGPT", setCoraGPTEndpoints);
        fetchEndpoints("Room Staging", setRoomStagingEndpoints);
        fetchEndpoints("Room Remodeling", setRoomRemodelingEndpoints);
        fetchEndpoints("Room Generation", setRoomGenerationEndpoints);
        fetchEndpoints("Listing Video", setListingVideoEndpoints);
        fetchEndpoints("Agent Profile Video", setAgentVideoEndpoints);
        fetchEndpoints("AI Listing Generation", setListingGenerationEndpoints);
        fetchEndpoints("Generate Lease Agreement", setLeaseAgreementEndpoints);
        fetchEndpoints("Generate QR Code", setGenerateQRCodeEndpoints);
        fetchEndpoints("3D Floor Plan Generation", setFloorPlanEndpoints);
        fetchEndpoints("Generate Post", setGeneratePostEndpoints);
        fetchEndpoints("Style Transfer", setStyleTransferEndpoints);

    }, []);

    const fetchEndpoints = async (name: any, setter: any) => {
        try {
            const data = await GetEndpoints(name);
            console.log(data);
            setter(data);
        } catch (error) {
            console.error("Error fetching endpoints:", error);
        }
    };

    return (
        <div className="container-xxl flex-grow-1 container-p-y ">
            <div className="row">
                <div className="col-md-9">
                    <h5 className="generate-title">Listing</h5>
                </div>
            </div>
            <div className="row">
                <div className="col-md-3 mb-2 ">
                    <div className="card">
                        <a href={`/generate/ai_generation`} className={`card-body generate-card generate-card-height ${storedPlatforms[0].generate.includes('all') || storedPlatforms[0].generate.includes('IA Listing Generation') ? "" : "disabled"}`}>
                            <div className="rounded backg-green">
                                <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M21.3333 2.66669H10.6667C6 2.66669 4 5.33335 4 9.33335V22.6667C4 26.6667 6 29.3334 10.6667 29.3334H21.3333C26 29.3334 28 26.6667 28 22.6667V9.33335C28 5.33335 26 2.66669 21.3333 2.66669ZM10.6667 16.3334H16C16.5467 16.3334 17 16.7867 17 17.3334C17 17.88 16.5467 18.3334 16 18.3334H10.6667C10.12 18.3334 9.66667 17.88 9.66667 17.3334C9.66667 16.7867 10.12 16.3334 10.6667 16.3334ZM21.3333 23.6667H10.6667C10.12 23.6667 9.66667 23.2134 9.66667 22.6667C9.66667 22.12 10.12 21.6667 10.6667 21.6667H21.3333C21.88 21.6667 22.3333 22.12 22.3333 22.6667C22.3333 23.2134 21.88 23.6667 21.3333 23.6667ZM24.6667 12.3334H22C19.9733 12.3334 18.3333 10.6934 18.3333 8.66669V6.00002C18.3333 5.45335 18.7867 5.00002 19.3333 5.00002C19.88 5.00002 20.3333 5.45335 20.3333 6.00002V8.66669C20.3333 9.58669 21.08 10.3334 22 10.3334H24.6667C25.2133 10.3334 25.6667 10.7867 25.6667 11.3334C25.6667 11.88 25.2133 12.3334 24.6667 12.3334Z" fill="#46B49E" />
                                </svg>
                            </div>
                            <h2>
                                AI Listing Generation
                                <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M7.79664 18.0863C7.63039 18.0863 7.46414 18.025 7.33289 17.8938C7.07914 17.64 7.07914 17.22 7.33289 16.9663L13.0379 11.2613C13.4579 10.8413 13.4579 10.1588 13.0379 9.73877L7.33289 4.03377C7.07914 3.78002 7.07914 3.36002 7.33289 3.10627C7.58664 2.85252 8.00664 2.85252 8.26039 3.10627L13.9654 8.81127C14.4116 9.25752 14.6654 9.86127 14.6654 10.5C14.6654 11.1388 14.4204 11.7425 13.9654 12.1888L8.26039 17.8938C8.12914 18.0163 7.96289 18.0863 7.79664 18.0863Z" fill="#282828" />
                                </svg>
                            </h2>
                            <p>Create the entire listing using AI (Image editing, description, title, moderation etc..)</p>
                            <br /><p className="end_points">  {ListingGenerationEndpoints} {ListingGenerationEndpoints == 1 ? "Credit" : "Credits"} / 1 Listing</p>
                        </a>
                    </div>
                </div>

                <div className="col-md-3 mb-2 ">
                    <div className="card">
                        <a href={`/generate/floor-plan-generation`} className={`card-body generate-card generate-card-height ${storedPlatforms[0].generate.includes('all') || storedPlatforms[0].generate.includes('3D Floor Plan Generation') ? "" : "disabled"}`}>
                            <div className="rounded backg-green">
                                <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M2.66675 13.0003C2.12008 13.0003 1.66675 12.547 1.66675 12.0003V8.66699C1.66675 4.80033 4.81341 1.66699 8.66675 1.66699H12.0001C12.5467 1.66699 13.0001 2.12033 13.0001 2.66699C13.0001 3.21366 12.5467 3.66699 12.0001 3.66699H8.66675C5.90675 3.66699 3.66675 5.90699 3.66675 8.66699V12.0003C3.66675 12.547 3.21341 13.0003 2.66675 13.0003Z" fill="#46B49E" />
                                    <path d="M29.3333 13.0003C28.7867 13.0003 28.3333 12.547 28.3333 12.0003V8.66699C28.3333 5.90699 26.0933 3.66699 23.3333 3.66699H20C19.4533 3.66699 19 3.21366 19 2.66699C19 2.12033 19.4533 1.66699 20 1.66699H23.3333C27.1867 1.66699 30.3333 4.80033 30.3333 8.66699V12.0003C30.3333 12.547 29.88 13.0003 29.3333 13.0003Z" fill="#46B49E" />
                                    <path d="M23.3333 30.333H21.3333C20.7866 30.333 20.3333 29.8797 20.3333 29.333C20.3333 28.7863 20.7866 28.333 21.3333 28.333H23.3333C26.0933 28.333 28.3333 26.093 28.3333 23.333V21.333C28.3333 20.7863 28.7866 20.333 29.3333 20.333C29.8799 20.333 30.3333 20.7863 30.3333 21.333V23.333C30.3333 27.1997 27.1866 30.333 23.3333 30.333Z" fill="#46B49E" />
                                    <path d="M12.0001 30.3333H8.66675C4.81341 30.3333 1.66675 27.2 1.66675 23.3333V20C1.66675 19.4533 2.12008 19 2.66675 19C3.21341 19 3.66675 19.4533 3.66675 20V23.3333C3.66675 26.0933 5.90675 28.3333 8.66675 28.3333H12.0001C12.5467 28.3333 13.0001 28.7867 13.0001 29.3333C13.0001 29.88 12.5467 30.3333 12.0001 30.3333Z" fill="#46B49E" />
                                    <path d="M11.3334 15.1732C13.4542 15.1732 15.1734 13.4539 15.1734 11.3332C15.1734 9.21239 13.4542 7.49316 11.3334 7.49316C9.21263 7.49316 7.49341 9.21239 7.49341 11.3332C7.49341 13.4539 9.21263 15.1732 11.3334 15.1732Z" fill="#46B49E" />
                                    <path d="M10.0001 24.5065C11.3845 24.5065 12.5067 23.3842 12.5067 21.9998C12.5067 20.6154 11.3845 19.4932 10.0001 19.4932C8.61568 19.4932 7.49341 20.6154 7.49341 21.9998C7.49341 23.3842 8.61568 24.5065 10.0001 24.5065Z" fill="#46B49E" />
                                    <path d="M22.0001 12.5065C23.3845 12.5065 24.5067 11.3842 24.5067 9.99983C24.5067 8.61544 23.3845 7.49316 22.0001 7.49316C20.6157 7.49316 19.4934 8.61544 19.4934 9.99983C19.4934 11.3842 20.6157 12.5065 22.0001 12.5065Z" fill="#46B49E" />
                                    <path d="M20.6667 24.5071C22.7874 24.5071 24.5067 22.7879 24.5067 20.6671C24.5067 18.5464 22.7874 16.8271 20.6667 16.8271C18.5459 16.8271 16.8267 18.5464 16.8267 20.6671C16.8267 22.7879 18.5459 24.5071 20.6667 24.5071Z" fill="#46B49E" />
                                </svg>
                            </div>
                            <h2>
                                3D Floor Plan Generation
                                <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M7.79664 18.0863C7.63039 18.0863 7.46414 18.025 7.33289 17.8938C7.07914 17.64 7.07914 17.22 7.33289 16.9663L13.0379 11.2613C13.4579 10.8413 13.4579 10.1588 13.0379 9.73877L7.33289 4.03377C7.07914 3.78002 7.07914 3.36002 7.33289 3.10627C7.58664 2.85252 8.00664 2.85252 8.26039 3.10627L13.9654 8.81127C14.4116 9.25752 14.6654 9.86127 14.6654 10.5C14.6654 11.1388 14.4204 11.7425 13.9654 12.1888L8.26039 17.8938C8.12914 18.0163 7.96289 18.0863 7.79664 18.0863Z" fill="#282828" />
                                </svg>
                            </h2>
                            <p>Instantly generate 3D Floor plan from 2D</p>
                            <br /><p className="end_points">  {floorPlanEndpoints} {floorPlanEndpoints == 1 ? "Credit" : "Credits"} / 1 Listing</p>
                        </a>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-md-9">
                    <h5 className="generate-title marg-30">Text</h5>
                </div>
            </div>
            <div className="row">
                <div className="col-md-3 mb-2 ">
                    <div className="card">
                        <a href={`/generate/property-description`} className={`card-body generate-card generate-card-height ${storedPlatforms[0].generate.includes('all') || storedPlatforms[0].generate.includes('Description Generation') ? "" : "disabled"}`}>
                            <div className="rounded backg-green">
                                <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M21.3333 2.66669H10.6667C6 2.66669 4 5.33335 4 9.33335V22.6667C4 26.6667 6 29.3334 10.6667 29.3334H21.3333C26 29.3334 28 26.6667 28 22.6667V9.33335C28 5.33335 26 2.66669 21.3333 2.66669ZM10.6667 16.3334H16C16.5467 16.3334 17 16.7867 17 17.3334C17 17.88 16.5467 18.3334 16 18.3334H10.6667C10.12 18.3334 9.66667 17.88 9.66667 17.3334C9.66667 16.7867 10.12 16.3334 10.6667 16.3334ZM21.3333 23.6667H10.6667C10.12 23.6667 9.66667 23.2134 9.66667 22.6667C9.66667 22.12 10.12 21.6667 10.6667 21.6667H21.3333C21.88 21.6667 22.3333 22.12 22.3333 22.6667C22.3333 23.2134 21.88 23.6667 21.3333 23.6667ZM24.6667 12.3334H22C19.9733 12.3334 18.3333 10.6934 18.3333 8.66669V6.00002C18.3333 5.45335 18.7867 5.00002 19.3333 5.00002C19.88 5.00002 20.3333 5.45335 20.3333 6.00002V8.66669C20.3333 9.58669 21.08 10.3334 22 10.3334H24.6667C25.2133 10.3334 25.6667 10.7867 25.6667 11.3334C25.6667 11.88 25.2133 12.3334 24.6667 12.3334Z" fill="#46B49E" />
                                </svg>
                            </div>
                            <h2>
                                Property Description
                                <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M7.79664 18.0863C7.63039 18.0863 7.46414 18.025 7.33289 17.8938C7.07914 17.64 7.07914 17.22 7.33289 16.9663L13.0379 11.2613C13.4579 10.8413 13.4579 10.1588 13.0379 9.73877L7.33289 4.03377C7.07914 3.78002 7.07914 3.36002 7.33289 3.10627C7.58664 2.85252 8.00664 2.85252 8.26039 3.10627L13.9654 8.81127C14.4116 9.25752 14.6654 9.86127 14.6654 10.5C14.6654 11.1388 14.4204 11.7425 13.9654 12.1888L8.26039 17.8938C8.12914 18.0163 7.96289 18.0863 7.79664 18.0863Z" fill="#282828" />
                                </svg>
                            </h2>
                            <p>Generate property description using property details and details in images. </p>
                            <br /> <p className="end_points">{descriptionEndpoints} {descriptionEndpoints == 1 ? "Credit" : "Credits"} / 1 Text</p>
                        </a>
                    </div>
                </div>
                <div className="col-md-3 mb-2">
                    <div className="card">
                        <a href={`/generate/property-title`} className={`card-body generate-card generate-card-height ${storedPlatforms[0].generate.includes('all') || storedPlatforms[0].generate.includes('Title Generation') ? "" : "disabled"}`}>
                            <div className="rounded backg-red">
                                <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M19.1333 2.66669H12.8667C11.48 2.66669 10.3467 3.78669 10.3467 5.17335V6.42669C10.3467 7.81335 11.4667 8.93335 12.8533 8.93335H19.1333C20.52 8.93335 21.64 7.81335 21.64 6.42669V5.17335C21.6533 3.78669 20.52 2.66669 19.1333 2.66669Z" fill="#FF6F61" />
                                    <path d="M22.9867 6.42664C22.9867 8.54664 21.2534 10.28 19.1334 10.28H12.8667C10.7467 10.28 9.01339 8.54664 9.01339 6.42664C9.01339 5.67997 8.21339 5.21331 7.54672 5.55997C5.66672 6.55997 4.38672 8.54664 4.38672 10.8266V23.3733C4.38672 26.6533 7.06672 29.3333 10.3467 29.3333H21.6534C24.9334 29.3333 27.6134 26.6533 27.6134 23.3733V10.8266C27.6134 8.54664 26.3334 6.55997 24.4534 5.55997C23.7867 5.21331 22.9867 5.67997 22.9867 6.42664ZM16.5067 22.6H10.6667C10.1201 22.6 9.66672 22.1466 9.66672 21.6C9.66672 21.0533 10.1201 20.6 10.6667 20.6H16.5067C17.0534 20.6 17.5067 21.0533 17.5067 21.6C17.5067 22.1466 17.0534 22.6 16.5067 22.6ZM20.0001 17.2666H10.6667C10.1201 17.2666 9.66672 16.8133 9.66672 16.2666C9.66672 15.72 10.1201 15.2666 10.6667 15.2666H20.0001C20.5467 15.2666 21.0001 15.72 21.0001 16.2666C21.0001 16.8133 20.5467 17.2666 20.0001 17.2666Z" fill="#FF6F61" />
                                </svg>

                            </div>
                            <h2>
                                Property Title
                                <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M7.79664 18.0863C7.63039 18.0863 7.46414 18.025 7.33289 17.8938C7.07914 17.64 7.07914 17.22 7.33289 16.9663L13.0379 11.2613C13.4579 10.8413 13.4579 10.1588 13.0379 9.73877L7.33289 4.03377C7.07914 3.78002 7.07914 3.36002 7.33289 3.10627C7.58664 2.85252 8.00664 2.85252 8.26039 3.10627L13.9654 8.81127C14.4116 9.25752 14.6654 9.86127 14.6654 10.5C14.6654 11.1388 14.4204 11.7425 13.9654 12.1888L8.26039 17.8938C8.12914 18.0163 7.96289 18.0863 7.79664 18.0863Z" fill="#282828" />
                                </svg>
                            </h2>
                            <p> Generate property title using property details and details in images.</p>
                            <br /><p className="end_points"> {titleEndpoints} {titleEndpoints == 1 ? "Credit" : "Credits"} / 1 Text </p>

                        </a>
                    </div>
                </div>
                <div className="col-md-3 mb-2">
                    <div className="card">
                        <a href={`/generate/coraGPT`} className={`card-body generate-card generate-card-height ${storedPlatforms[0].generate.includes('all') || storedPlatforms[0].generate.includes('CoraGPT') ? "" : "disabled"}`}>
                            <div className="rounded backg-mauve">
                                <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M16.0003 2.66669C8.64033 2.66669 2.66699 8.64002 2.66699 16C2.66699 23.36 8.64033 29.3334 16.0003 29.3334C23.3603 29.3334 29.3337 23.36 29.3337 16C29.3337 8.64002 23.3603 2.66669 16.0003 2.66669ZM16.3203 20.3334C17.3603 20.3334 18.387 19.9467 19.1737 19.2534C19.587 18.8934 20.227 18.9334 20.587 19.3467C20.947 19.76 20.907 20.4 20.4937 20.76C19.3337 21.7867 17.8537 22.3467 16.3203 22.3467C12.827 22.3467 9.98699 19.5067 9.98699 16.0134C9.98699 12.52 12.827 9.68002 16.3203 9.68002C17.8537 9.68002 19.347 10.24 20.4937 11.2667C20.907 11.6267 20.947 12.2667 20.587 12.68C20.2137 13.0934 19.587 13.1334 19.1737 12.7734C18.387 12.08 17.3737 11.6934 16.3203 11.6934C13.9337 11.6934 11.987 13.64 11.987 16.0267C11.987 18.4134 13.9337 20.3334 16.3203 20.3334Z" fill="#7E57C2" />
                                </svg>
                            </div>
                            <h2>
                                CoraGPT
                                <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M7.79664 18.0863C7.63039 18.0863 7.46414 18.025 7.33289 17.8938C7.07914 17.64 7.07914 17.22 7.33289 16.9663L13.0379 11.2613C13.4579 10.8413 13.4579 10.1588 13.0379 9.73877L7.33289 4.03377C7.07914 3.78002 7.07914 3.36002 7.33289 3.10627C7.58664 2.85252 8.00664 2.85252 8.26039 3.10627L13.9654 8.81127C14.4116 9.25752 14.6654 9.86127 14.6654 10.5C14.6654 11.1388 14.4204 11.7425 13.9654 12.1888L8.26039 17.8938C8.12914 18.0163 7.96289 18.0863 7.79664 18.0863Z" fill="#282828" />
                                </svg>

                            </h2>
                            <p>Beta</p>
                            <br /><p className="end_points"> {coraGPTEndpoints} {coraGPTEndpoints == 1 ? "Credit" : "Credits"} / 1 Text</p>
                        </a>
                    </div>
                </div>
                <div className="col-md-3 mb-2">
                    <div className="card">
                        <a href={`/generate/generate-lease-agreement`} className={`card-body generate-card generate-card-height ${storedPlatforms[0].generate.includes('all') || storedPlatforms[0].generate.includes('Lease Generation') ? "" : "disabled"}`}>
                            <div className="rounded backg-mauve">
                                <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M16.0003 2.66669C8.64033 2.66669 2.66699 8.64002 2.66699 16C2.66699 23.36 8.64033 29.3334 16.0003 29.3334C23.3603 29.3334 29.3337 23.36 29.3337 16C29.3337 8.64002 23.3603 2.66669 16.0003 2.66669ZM16.3203 20.3334C17.3603 20.3334 18.387 19.9467 19.1737 19.2534C19.587 18.8934 20.227 18.9334 20.587 19.3467C20.947 19.76 20.907 20.4 20.4937 20.76C19.3337 21.7867 17.8537 22.3467 16.3203 22.3467C12.827 22.3467 9.98699 19.5067 9.98699 16.0134C9.98699 12.52 12.827 9.68002 16.3203 9.68002C17.8537 9.68002 19.347 10.24 20.4937 11.2667C20.907 11.6267 20.947 12.2667 20.587 12.68C20.2137 13.0934 19.587 13.1334 19.1737 12.7734C18.387 12.08 17.3737 11.6934 16.3203 11.6934C13.9337 11.6934 11.987 13.64 11.987 16.0267C11.987 18.4134 13.9337 20.3334 16.3203 20.3334Z" fill="#7E57C2" />
                                </svg>
                            </div>
                            <h2>
                                Generate Lease Agreement<br /> (Dubai)
                                <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M7.79664 18.0863C7.63039 18.0863 7.46414 18.025 7.33289 17.8938C7.07914 17.64 7.07914 17.22 7.33289 16.9663L13.0379 11.2613C13.4579 10.8413 13.4579 10.1588 13.0379 9.73877L7.33289 4.03377C7.07914 3.78002 7.07914 3.36002 7.33289 3.10627C7.58664 2.85252 8.00664 2.85252 8.26039 3.10627L13.9654 8.81127C14.4116 9.25752 14.6654 9.86127 14.6654 10.5C14.6654 11.1388 14.4204 11.7425 13.9654 12.1888L8.26039 17.8938C8.12914 18.0163 7.96289 18.0863 7.79664 18.0863Z" fill="#282828" />
                                </svg>

                            </h2>
                            <p>Generate Lease Agreements in seconds using OCR to
                                automatically extract data from ID documents and title deeds.</p>
                            <br /><p className="end_points">{leaseAgreementEndpoints} {leaseAgreementEndpoints == 1 ? "Credit" : "Credits"} / 1 Document Generated</p>
                        </a>
                    </div>
                </div>
            </div>
            <div className="row">
                <h5 className="generate-title marg-30">Image</h5>
            </div>
            <div className="row">
                <div className="col-md-3 mb-2 ">
                    <div className="card">
                        <a href={`/generate/room-staging`} className={`card-body generate-card generate-card-height ${storedPlatforms[0].generate.includes('all') || storedPlatforms[0].generate.includes('Room Staging') ? "" : "disabled"}`}>
                            <div className="rounded backg-blue">
                                <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M7.10682 25.8933C6.96016 25.8933 6.80016 25.8533 6.66682 25.7867L3.70682 24.3067C3.37349 24.1333 3.16016 23.7867 3.16016 23.4133V19.7067C3.16016 19.16 3.61349 18.7067 4.16016 18.7067C4.70682 18.7067 5.16016 19.16 5.16016 19.7067V22.7867L7.56016 24C8.05349 24.24 8.25349 24.84 8.00016 25.3333C7.82682 25.68 7.48016 25.8933 7.10682 25.8933Z" fill="#5782C2" />
                                    <path d="M16.0003 30.3333C15.9203 30.3333 15.8403 30.32 15.7736 30.3066C15.6936 30.2933 15.6136 30.2533 15.5336 30.2133L12.5869 28.7466C12.0936 28.4933 11.8936 27.8933 12.1336 27.4C12.3869 26.9066 12.9869 26.7066 13.4803 26.9466L15.0003 27.7066V25.6266C15.0003 25.08 15.4536 24.6266 16.0003 24.6266C16.5469 24.6266 17.0003 25.08 17.0003 25.6266V27.7066L18.5203 26.9466C19.0136 26.7066 19.6136 26.9066 19.8669 27.4C20.1203 27.8933 19.9203 28.4933 19.4136 28.7466L16.4669 30.2133C16.3869 30.2533 16.3069 30.28 16.2269 30.3066C16.1469 30.32 16.0803 30.3333 16.0003 30.3333Z" fill="#5782C2" />
                                    <path d="M24.8936 25.8933C24.5336 25.8933 24.1736 25.6933 24.0002 25.3466C23.7469 24.8533 23.9469 24.2533 24.4536 24L26.8669 22.8V19.72C26.8669 19.1733 27.3202 18.72 27.8669 18.72C28.4136 18.72 28.8669 19.1733 28.8669 19.72V23.4266C28.8669 23.8 28.6536 24.1466 28.3202 24.32L25.3336 25.7866C25.1869 25.8533 25.0402 25.8933 24.8936 25.8933Z" fill="#5782C2" />
                                    <path d="M16.0003 19.2267C15.4536 19.2267 15.0003 18.7733 15.0003 18.2267V15.1467L12.5869 13.9467C12.0936 13.6933 11.8936 13.0933 12.1336 12.6C12.3869 12.1067 12.9869 11.9067 13.4803 12.1467L16.0003 13.4L18.5203 12.1467C19.0136 11.9067 19.6136 12.0933 19.8669 12.6C20.1203 13.0933 19.9203 13.6933 19.4136 13.9467L17.0003 15.1467V18.2267C17.0003 18.7733 16.5469 19.2267 16.0003 19.2267Z" fill="#5782C2" />
                                    <path d="M4.14648 13.2933C3.59982 13.2933 3.14648 12.84 3.14648 12.2933V8.61332V8.59999C3.14648 8.51999 3.15982 8.43999 3.17315 8.37332C3.19982 8.25332 3.23982 8.15999 3.29315 8.06665C3.33315 7.99999 3.38648 7.94665 3.43982 7.89332C3.49315 7.83999 3.55982 7.78665 3.62648 7.74665C3.65315 7.73332 3.69315 7.70665 3.71982 7.69332L6.66648 6.21332C7.15982 5.97332 7.75982 6.17332 7.99982 6.66665C8.25315 7.15999 8.05315 7.75999 7.55982 7.99999L6.38648 8.58665L7.55982 9.17332C8.05315 9.42665 8.25315 10.0267 7.99982 10.52C7.75982 11.0133 7.15982 11.2133 6.66648 10.9733L5.14648 10.2133V12.2933C5.14648 12.8533 4.70648 13.2933 4.14648 13.2933Z" fill="#5782C2" />
                                    <path d="M18.9604 5.14666C18.8137 5.14666 18.6537 5.10666 18.5204 5.04L16.0004 3.78666L13.4804 5.04C12.9871 5.29333 12.3871 5.09333 12.1471 4.6C11.8937 4.10666 12.0937 3.50666 12.5871 3.25333L15.5471 1.77333C15.8271 1.62666 16.1604 1.62666 16.4404 1.77333L19.4004 3.25333C19.8937 3.50666 20.0937 4.10666 19.8537 4.6C19.6804 4.94666 19.3337 5.14666 18.9604 5.14666Z" fill="#5782C2" />
                                    <path d="M27.8529 13.2933C27.3063 13.2933 26.8529 12.84 26.8529 12.2933V10.2133L25.3329 10.9733C24.8396 11.2133 24.2396 11.0133 23.9996 10.52C23.7463 10.0267 23.9463 9.42665 24.4529 9.17332L25.6263 8.58665L24.4396 7.99999C23.9463 7.75999 23.7463 7.15999 23.9996 6.66665C24.2529 6.17332 24.8396 5.97332 25.3329 6.21332L28.2796 7.67999C28.3063 7.69332 28.3463 7.70665 28.3729 7.73332C28.4529 7.77332 28.5196 7.83999 28.5863 7.90665C28.6263 7.95999 28.6663 8.01332 28.7063 8.06665C28.7596 8.15999 28.7996 8.25332 28.8263 8.35999C28.8396 8.43999 28.8529 8.51999 28.8529 8.58665V8.59999V12.28C28.8529 12.8533 28.3996 13.2933 27.8529 13.2933Z" fill="#5782C2" />
                                </svg>

                            </div>
                            <h2>
                                Room Staging
                                <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M7.79664 18.0863C7.63039 18.0863 7.46414 18.025 7.33289 17.8938C7.07914 17.64 7.07914 17.22 7.33289 16.9663L13.0379 11.2613C13.4579 10.8413 13.4579 10.1588 13.0379 9.73877L7.33289 4.03377C7.07914 3.78002 7.07914 3.36002 7.33289 3.10627C7.58664 2.85252 8.00664 2.85252 8.26039 3.10627L13.9654 8.81127C14.4116 9.25752 14.6654 9.86127 14.6654 10.5C14.6654 11.1388 14.4204 11.7425 13.9654 12.1888L8.26039 17.8938C8.12914 18.0163 7.96289 18.0863 7.79664 18.0863Z" fill="#282828" />
                                </svg>
                            </h2>
                            <p>Transform and enhance the visual appeal of rooms to make them more inviting and attractive for various purposes.</p>
                            <br /><p className="end_points"> {roomStagingEndpoints} {roomStagingEndpoints == 1 ? "Credit" : "Credits"} / 1 Image</p>
                        </a>
                    </div>
                </div>
                <div className="col-md-3 mb-2 ">
                    <div className="card">
                        <a href={`/generate/room-remodeling`} className={`card-body generate-card generate-card-height ${storedPlatforms[0].generate.includes('all') || storedPlatforms[0].generate.includes('Room Remodeling') ? "" : "disabled"}`}>
                            <div className="rounded backg-blue">
                                <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M7.10682 25.8933C6.96016 25.8933 6.80016 25.8533 6.66682 25.7867L3.70682 24.3067C3.37349 24.1333 3.16016 23.7867 3.16016 23.4133V19.7067C3.16016 19.16 3.61349 18.7067 4.16016 18.7067C4.70682 18.7067 5.16016 19.16 5.16016 19.7067V22.7867L7.56016 24C8.05349 24.24 8.25349 24.84 8.00016 25.3333C7.82682 25.68 7.48016 25.8933 7.10682 25.8933Z" fill="#5782C2" />
                                    <path d="M16.0003 30.3333C15.9203 30.3333 15.8403 30.32 15.7736 30.3066C15.6936 30.2933 15.6136 30.2533 15.5336 30.2133L12.5869 28.7466C12.0936 28.4933 11.8936 27.8933 12.1336 27.4C12.3869 26.9066 12.9869 26.7066 13.4803 26.9466L15.0003 27.7066V25.6266C15.0003 25.08 15.4536 24.6266 16.0003 24.6266C16.5469 24.6266 17.0003 25.08 17.0003 25.6266V27.7066L18.5203 26.9466C19.0136 26.7066 19.6136 26.9066 19.8669 27.4C20.1203 27.8933 19.9203 28.4933 19.4136 28.7466L16.4669 30.2133C16.3869 30.2533 16.3069 30.28 16.2269 30.3066C16.1469 30.32 16.0803 30.3333 16.0003 30.3333Z" fill="#5782C2" />
                                    <path d="M24.8936 25.8933C24.5336 25.8933 24.1736 25.6933 24.0002 25.3466C23.7469 24.8533 23.9469 24.2533 24.4536 24L26.8669 22.8V19.72C26.8669 19.1733 27.3202 18.72 27.8669 18.72C28.4136 18.72 28.8669 19.1733 28.8669 19.72V23.4266C28.8669 23.8 28.6536 24.1466 28.3202 24.32L25.3336 25.7866C25.1869 25.8533 25.0402 25.8933 24.8936 25.8933Z" fill="#5782C2" />
                                    <path d="M16.0003 19.2267C15.4536 19.2267 15.0003 18.7733 15.0003 18.2267V15.1467L12.5869 13.9467C12.0936 13.6933 11.8936 13.0933 12.1336 12.6C12.3869 12.1067 12.9869 11.9067 13.4803 12.1467L16.0003 13.4L18.5203 12.1467C19.0136 11.9067 19.6136 12.0933 19.8669 12.6C20.1203 13.0933 19.9203 13.6933 19.4136 13.9467L17.0003 15.1467V18.2267C17.0003 18.7733 16.5469 19.2267 16.0003 19.2267Z" fill="#5782C2" />
                                    <path d="M4.14648 13.2933C3.59982 13.2933 3.14648 12.84 3.14648 12.2933V8.61332V8.59999C3.14648 8.51999 3.15982 8.43999 3.17315 8.37332C3.19982 8.25332 3.23982 8.15999 3.29315 8.06665C3.33315 7.99999 3.38648 7.94665 3.43982 7.89332C3.49315 7.83999 3.55982 7.78665 3.62648 7.74665C3.65315 7.73332 3.69315 7.70665 3.71982 7.69332L6.66648 6.21332C7.15982 5.97332 7.75982 6.17332 7.99982 6.66665C8.25315 7.15999 8.05315 7.75999 7.55982 7.99999L6.38648 8.58665L7.55982 9.17332C8.05315 9.42665 8.25315 10.0267 7.99982 10.52C7.75982 11.0133 7.15982 11.2133 6.66648 10.9733L5.14648 10.2133V12.2933C5.14648 12.8533 4.70648 13.2933 4.14648 13.2933Z" fill="#5782C2" />
                                    <path d="M18.9604 5.14666C18.8137 5.14666 18.6537 5.10666 18.5204 5.04L16.0004 3.78666L13.4804 5.04C12.9871 5.29333 12.3871 5.09333 12.1471 4.6C11.8937 4.10666 12.0937 3.50666 12.5871 3.25333L15.5471 1.77333C15.8271 1.62666 16.1604 1.62666 16.4404 1.77333L19.4004 3.25333C19.8937 3.50666 20.0937 4.10666 19.8537 4.6C19.6804 4.94666 19.3337 5.14666 18.9604 5.14666Z" fill="#5782C2" />
                                    <path d="M27.8529 13.2933C27.3063 13.2933 26.8529 12.84 26.8529 12.2933V10.2133L25.3329 10.9733C24.8396 11.2133 24.2396 11.0133 23.9996 10.52C23.7463 10.0267 23.9463 9.42665 24.4529 9.17332L25.6263 8.58665L24.4396 7.99999C23.9463 7.75999 23.7463 7.15999 23.9996 6.66665C24.2529 6.17332 24.8396 5.97332 25.3329 6.21332L28.2796 7.67999C28.3063 7.69332 28.3463 7.70665 28.3729 7.73332C28.4529 7.77332 28.5196 7.83999 28.5863 7.90665C28.6263 7.95999 28.6663 8.01332 28.7063 8.06665C28.7596 8.15999 28.7996 8.25332 28.8263 8.35999C28.8396 8.43999 28.8529 8.51999 28.8529 8.58665V8.59999V12.28C28.8529 12.8533 28.3996 13.2933 27.8529 13.2933Z" fill="#5782C2" />
                                </svg>
                            </div>
                            <h2>
                                Room Remodeling
                                <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M7.79664 18.0863C7.63039 18.0863 7.46414 18.025 7.33289 17.8938C7.07914 17.64 7.07914 17.22 7.33289 16.9663L13.0379 11.2613C13.4579 10.8413 13.4579 10.1588 13.0379 9.73877L7.33289 4.03377C7.07914 3.78002 7.07914 3.36002 7.33289 3.10627C7.58664 2.85252 8.00664 2.85252 8.26039 3.10627L13.9654 8.81127C14.4116 9.25752 14.6654 9.86127 14.6654 10.5C14.6654 11.1388 14.4204 11.7425 13.9654 12.1888L8.26039 17.8938C8.12914 18.0163 7.96289 18.0863 7.79664 18.0863Z" fill="#282828" />
                                </svg>
                            </h2>
                            <p>Visualize and plan room makeovers or renovations, allowing you to explore different design options and layouts.</p>
                            <br /><p className="end_points"> {roomRemodelingEndpoints} {roomRemodelingEndpoints == 1 ? "Credit" : "Credits"} / 1 Image</p>
                        </a>
                    </div>
                </div>
                <div className="col-md-3 mb-2">
                    <div className="card">
                        <a href={`/generate/room-image-generation`} className={`card-body generate-card generate-card-height ${storedPlatforms[0].generate.includes('all') || storedPlatforms[0].generate.includes('Room Generation') ? "" : "disabled"}`}>
                            <div className="rounded backg-orange">
                                <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M3.44007 25.3467L3.4134 25.3733C3.0534 24.5867 2.82673 23.6933 2.7334 22.7067C2.82673 23.68 3.08007 24.56 3.44007 25.3467Z" fill="#FCAF3C" />
                                    <path d="M12.0005 13.84C13.7531 13.84 15.1738 12.4193 15.1738 10.6667C15.1738 8.9141 13.7531 7.49335 12.0005 7.49335C10.2479 7.49335 8.82715 8.9141 8.82715 10.6667C8.82715 12.4193 10.2479 13.84 12.0005 13.84Z" fill="#FCAF3C" />
                                    <path d="M21.587 2.66669H10.4137C5.56033 2.66669 2.66699 5.56002 2.66699 10.4134V21.5867C2.66699 23.04 2.92033 24.3067 3.41366 25.3734C4.56033 27.9067 7.01366 29.3334 10.4137 29.3334H21.587C26.4403 29.3334 29.3337 26.44 29.3337 21.5867V18.5334V10.4134C29.3337 5.56002 26.4403 2.66669 21.587 2.66669ZM27.1603 16.6667C26.1203 15.7734 24.4403 15.7734 23.4003 16.6667L17.8537 21.4267C16.8137 22.32 15.1337 22.32 14.0937 21.4267L13.6403 21.0534C12.6937 20.2267 11.187 20.1467 10.1203 20.8667L5.13366 24.2134C4.84033 23.4667 4.66699 22.6 4.66699 21.5867V10.4134C4.66699 6.65335 6.65366 4.66669 10.4137 4.66669H21.587C25.347 4.66669 27.3337 6.65335 27.3337 10.4134V16.8134L27.1603 16.6667Z" fill="#FCAF3C" />
                                </svg>

                            </div>
                            <h2>
                                Room Image Generation
                                <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M7.79664 18.0863C7.63039 18.0863 7.46414 18.025 7.33289 17.8938C7.07914 17.64 7.07914 17.22 7.33289 16.9663L13.0379 11.2613C13.4579 10.8413 13.4579 10.1588 13.0379 9.73877L7.33289 4.03377C7.07914 3.78002 7.07914 3.36002 7.33289 3.10627C7.58664 2.85252 8.00664 2.85252 8.26039 3.10627L13.9654 8.81127C14.4116 9.25752 14.6654 9.86127 14.6654 10.5C14.6654 11.1388 14.4204 11.7425 13.9654 12.1888L8.26039 17.8938C8.12914 18.0163 7.96289 18.0863 7.79664 18.0863Z" fill="#282828" />
                                </svg>
                            </h2>
                            <p>Automatically generate images of rooms based on descriptions or specifications, providing a quick and easy way to visualize spaces.</p>
                            <br /><p className="end_points"> {roomGeneratgionEndpoints} {roomGeneratgionEndpoints == 1 ? "Credit" : "Credits"} / 1 Image</p>
                        </a>
                    </div>
                </div>
                <div className="col-md-3 mb-2">
                    <div className="card">
                        <a href={`/generate/generate-QR-code`} className={`card-body generate-card generate-card-height ${storedPlatforms[0].generate.includes('all') || storedPlatforms[0].generate.includes('QR code Generation') ? "" : "disabled"}`}>
                            <div className="rounded backg-orange">
                                <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M3.44007 25.3467L3.4134 25.3733C3.0534 24.5867 2.82673 23.6933 2.7334 22.7067C2.82673 23.68 3.08007 24.56 3.44007 25.3467Z" fill="#FCAF3C" />
                                    <path d="M12.0005 13.84C13.7531 13.84 15.1738 12.4193 15.1738 10.6667C15.1738 8.9141 13.7531 7.49335 12.0005 7.49335C10.2479 7.49335 8.82715 8.9141 8.82715 10.6667C8.82715 12.4193 10.2479 13.84 12.0005 13.84Z" fill="#FCAF3C" />
                                    <path d="M21.587 2.66669H10.4137C5.56033 2.66669 2.66699 5.56002 2.66699 10.4134V21.5867C2.66699 23.04 2.92033 24.3067 3.41366 25.3734C4.56033 27.9067 7.01366 29.3334 10.4137 29.3334H21.587C26.4403 29.3334 29.3337 26.44 29.3337 21.5867V18.5334V10.4134C29.3337 5.56002 26.4403 2.66669 21.587 2.66669ZM27.1603 16.6667C26.1203 15.7734 24.4403 15.7734 23.4003 16.6667L17.8537 21.4267C16.8137 22.32 15.1337 22.32 14.0937 21.4267L13.6403 21.0534C12.6937 20.2267 11.187 20.1467 10.1203 20.8667L5.13366 24.2134C4.84033 23.4667 4.66699 22.6 4.66699 21.5867V10.4134C4.66699 6.65335 6.65366 4.66669 10.4137 4.66669H21.587C25.347 4.66669 27.3337 6.65335 27.3337 10.4134V16.8134L27.1603 16.6667Z" fill="#FCAF3C" />
                                </svg>

                            </div>
                            <h2>
                                Generate QR code
                                <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M7.79664 18.0863C7.63039 18.0863 7.46414 18.025 7.33289 17.8938C7.07914 17.64 7.07914 17.22 7.33289 16.9663L13.0379 11.2613C13.4579 10.8413 13.4579 10.1588 13.0379 9.73877L7.33289 4.03377C7.07914 3.78002 7.07914 3.36002 7.33289 3.10627C7.58664 2.85252 8.00664 2.85252 8.26039 3.10627L13.9654 8.81127C14.4116 9.25752 14.6654 9.86127 14.6654 10.5C14.6654 11.1388 14.4204 11.7425 13.9654 12.1888L8.26039 17.8938C8.12914 18.0163 7.96289 18.0863 7.79664 18.0863Z" fill="#282828" />
                                </svg>
                            </h2>
                            <p>A feature that allows you to generate QR codes to easily and quickly
                                encode data and links.</p>
                            <br /><p className="end_points">{generateQRCodeEndpoints} {generateQRCodeEndpoints == 1 ? "Credit" : "Credits"} / 1 Image </p>
                        </a>
                    </div>
                </div>
                {/* <div className="col-md-3 mb-2">
                    <div className="card">
                        <a href={`/generate/list-post`} className={`card-body generate-card generate-card-height ${storedPlatforms[0].generate.includes('all') || storedPlatforms[0].generate.includes('Generate Post') ? "" : "disabled"}`}>
                            <div className="rounded backg-orange">
                                <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M3.44007 25.3467L3.4134 25.3733C3.0534 24.5867 2.82673 23.6933 2.7334 22.7067C2.82673 23.68 3.08007 24.56 3.44007 25.3467Z" fill="#FCAF3C" />
                                    <path d="M12.0005 13.84C13.7531 13.84 15.1738 12.4193 15.1738 10.6667C15.1738 8.9141 13.7531 7.49335 12.0005 7.49335C10.2479 7.49335 8.82715 8.9141 8.82715 10.6667C8.82715 12.4193 10.2479 13.84 12.0005 13.84Z" fill="#FCAF3C" />
                                    <path d="M21.587 2.66669H10.4137C5.56033 2.66669 2.66699 5.56002 2.66699 10.4134V21.5867C2.66699 23.04 2.92033 24.3067 3.41366 25.3734C4.56033 27.9067 7.01366 29.3334 10.4137 29.3334H21.587C26.4403 29.3334 29.3337 26.44 29.3337 21.5867V18.5334V10.4134C29.3337 5.56002 26.4403 2.66669 21.587 2.66669ZM27.1603 16.6667C26.1203 15.7734 24.4403 15.7734 23.4003 16.6667L17.8537 21.4267C16.8137 22.32 15.1337 22.32 14.0937 21.4267L13.6403 21.0534C12.6937 20.2267 11.187 20.1467 10.1203 20.8667L5.13366 24.2134C4.84033 23.4667 4.66699 22.6 4.66699 21.5867V10.4134C4.66699 6.65335 6.65366 4.66669 10.4137 4.66669H21.587C25.347 4.66669 27.3337 6.65335 27.3337 10.4134V16.8134L27.1603 16.6667Z" fill="#FCAF3C" />
                                </svg>

                            </div>
                            <h2>
                                Generate Poster / Reel
                                <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M7.79664 18.0863C7.63039 18.0863 7.46414 18.025 7.33289 17.8938C7.07914 17.64 7.07914 17.22 7.33289 16.9663L13.0379 11.2613C13.4579 10.8413 13.4579 10.1588 13.0379 9.73877L7.33289 4.03377C7.07914 3.78002 7.07914 3.36002 7.33289 3.10627C7.58664 2.85252 8.00664 2.85252 8.26039 3.10627L13.9654 8.81127C14.4116 9.25752 14.6654 9.86127 14.6654 10.5C14.6654 11.1388 14.4204 11.7425 13.9654 12.1888L8.26039 17.8938C8.12914 18.0163 7.96289 18.0863 7.79664 18.0863Z" fill="#282828" />
                                </svg>
                            </h2>
                            <p></p>
                            <br /><p className="end_points">{generatePostEndpoints} {generatePostEndpoints == 1 ? "Credit" : "Credits"} / 1 Image </p>
                        </a>
                    </div>
                </div> */}
                <div className="col-md-3 mb-2">
                    <div className="card">
                        <a href={`/generate/style-transfer`} className={`card-body generate-card generate-card-height ${storedPlatforms[0].generate.includes('all') || storedPlatforms[0].generate.includes('QR code Generation') ? "" : "disabled"}`}>
                            <div className="rounded backg-orange">
                                <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M3.44007 25.3467L3.4134 25.3733C3.0534 24.5867 2.82673 23.6933 2.7334 22.7067C2.82673 23.68 3.08007 24.56 3.44007 25.3467Z" fill="#FCAF3C" />
                                    <path d="M12.0005 13.84C13.7531 13.84 15.1738 12.4193 15.1738 10.6667C15.1738 8.9141 13.7531 7.49335 12.0005 7.49335C10.2479 7.49335 8.82715 8.9141 8.82715 10.6667C8.82715 12.4193 10.2479 13.84 12.0005 13.84Z" fill="#FCAF3C" />
                                    <path d="M21.587 2.66669H10.4137C5.56033 2.66669 2.66699 5.56002 2.66699 10.4134V21.5867C2.66699 23.04 2.92033 24.3067 3.41366 25.3734C4.56033 27.9067 7.01366 29.3334 10.4137 29.3334H21.587C26.4403 29.3334 29.3337 26.44 29.3337 21.5867V18.5334V10.4134C29.3337 5.56002 26.4403 2.66669 21.587 2.66669ZM27.1603 16.6667C26.1203 15.7734 24.4403 15.7734 23.4003 16.6667L17.8537 21.4267C16.8137 22.32 15.1337 22.32 14.0937 21.4267L13.6403 21.0534C12.6937 20.2267 11.187 20.1467 10.1203 20.8667L5.13366 24.2134C4.84033 23.4667 4.66699 22.6 4.66699 21.5867V10.4134C4.66699 6.65335 6.65366 4.66669 10.4137 4.66669H21.587C25.347 4.66669 27.3337 6.65335 27.3337 10.4134V16.8134L27.1603 16.6667Z" fill="#FCAF3C" />
                                </svg>

                            </div>
                            <h2>
                                Style Transfer
                                <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M7.79664 18.0863C7.63039 18.0863 7.46414 18.025 7.33289 17.8938C7.07914 17.64 7.07914 17.22 7.33289 16.9663L13.0379 11.2613C13.4579 10.8413 13.4579 10.1588 13.0379 9.73877L7.33289 4.03377C7.07914 3.78002 7.07914 3.36002 7.33289 3.10627C7.58664 2.85252 8.00664 2.85252 8.26039 3.10627L13.9654 8.81127C14.4116 9.25752 14.6654 9.86127 14.6654 10.5C14.6654 11.1388 14.4204 11.7425 13.9654 12.1888L8.26039 17.8938C8.12914 18.0163 7.96289 18.0863 7.79664 18.0863Z" fill="#282828" />
                                </svg>
                            </h2>
                            <p> </p>
                            <br /><p className="end_points">{styleTransferEndpoints} {styleTransferEndpoints == 1 ? "Credit" : "Credits"} / 1 Image </p>
                        </a>
                    </div>
                </div>

            </div>
            <div className="row">
                <h5 className="generate-title marg-30">Video</h5>
            </div>
            <div className="row card-bottom">

                <div className="col-md-3 mb-2 ">
                    <div className="card">
                        <a href={`/generate/listing-video`} className={`card-body generate-card generate-card-height ${storedPlatforms[0].generate.includes('all') || storedPlatforms[0].generate.includes('Listing Video') ? "" : "disabled"}`}>
                            <div className="rounded backg-marron">
                                <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M21.5867 2.66666H17V10.6667V11.6667V18.3333H29.3333V11.6667V10.6667V10.4133C29.3333 5.55999 26.44 2.66666 21.5867 2.66666Z" fill="#C29157" />
                                    <path d="M2.66699 13.6667V20.3333V21V21.5867C2.66699 26.44 5.56033 29.3333 10.4137 29.3333H15.0003V21V20.3333V13.6667H2.66699Z" fill="#C29157" />
                                    <path d="M15.0003 2.66666V11.6667H2.66699V10.4133C2.66699 5.55999 5.56033 2.66666 10.4137 2.66666H15.0003Z" fill="#C29157" />
                                    <path d="M29.3333 20.3333V21.5867C29.3333 26.44 26.44 29.3333 21.5867 29.3333H17V20.3333H29.3333Z" fill="#C29157" />
                                </svg>
                            </div>
                            <h2>
                                Listing Video
                                <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M7.79664 18.0863C7.63039 18.0863 7.46414 18.025 7.33289 17.8938C7.07914 17.64 7.07914 17.22 7.33289 16.9663L13.0379 11.2613C13.4579 10.8413 13.4579 10.1588 13.0379 9.73877L7.33289 4.03377C7.07914 3.78002 7.07914 3.36002 7.33289 3.10627C7.58664 2.85252 8.00664 2.85252 8.26039 3.10627L13.9654 8.81127C14.4116 9.25752 14.6654 9.86127 14.6654 10.5C14.6654 11.1388 14.4204 11.7425 13.9654 12.1888L8.26039 17.8938C8.12914 18.0163 7.96289 18.0863 7.79664 18.0863Z" fill="#282828" />
                                </svg>
                            </h2>
                            <p>Create captivating video content to showcase properties and listings, making them more engaging for potential buyers or renters.</p>
                            <br /><p className="end_points">{listingVideoEndpoints} {listingVideoEndpoints == 1 ? "Credit" : "Credits"} / 1 Video</p>
                        </a>
                    </div>
                </div>


                <div className="col-md-3 mb-2 ">
                    <div className="card">
                        <a href={`/generate/agent-profile-video-reel`} className={`card-body generate-card generate-card-height ${storedPlatforms[0].generate.includes('all') || storedPlatforms[0].generate.includes('Agent Video') ? "" : "disabled"}`}>
                            <div className="rounded backg-marron">
                                <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M21.5867 2.66666H17V10.6667V11.6667V18.3333H29.3333V11.6667V10.6667V10.4133C29.3333 5.55999 26.44 2.66666 21.5867 2.66666Z" fill="#C29157" />
                                    <path d="M2.66699 13.6667V20.3333V21V21.5867C2.66699 26.44 5.56033 29.3333 10.4137 29.3333H15.0003V21V20.3333V13.6667H2.66699Z" fill="#C29157" />
                                    <path d="M15.0003 2.66666V11.6667H2.66699V10.4133C2.66699 5.55999 5.56033 2.66666 10.4137 2.66666H15.0003Z" fill="#C29157" />
                                    <path d="M29.3333 20.3333V21.5867C29.3333 26.44 26.44 29.3333 21.5867 29.3333H17V20.3333H29.3333Z" fill="#C29157" />
                                </svg>
                            </div>
                            <h2>
                                {storedPlatforms[0].generate.includes('all') || storedPlatforms[0].generate.includes('Agent Video') ? "Agent Profile Video (Reel) " : "Agent Profile Video Reel (Coming Soon) "}
                                <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M7.79664 18.0863C7.63039 18.0863 7.46414 18.025 7.33289 17.8938C7.07914 17.64 7.07914 17.22 7.33289 16.9663L13.0379 11.2613C13.4579 10.8413 13.4579 10.1588 13.0379 9.73877L7.33289 4.03377C7.07914 3.78002 7.07914 3.36002 7.33289 3.10627C7.58664 2.85252 8.00664 2.85252 8.26039 3.10627L13.9654 8.81127C14.4116 9.25752 14.6654 9.86127 14.6654 10.5C14.6654 11.1388 14.4204 11.7425 13.9654 12.1888L8.26039 17.8938C8.12914 18.0163 7.96289 18.0863 7.79664 18.0863Z" fill="#282828" />
                                </svg>
                            </h2>
                            <p>Craft professional videos to introduce and highlight real estate agents, helping clients get to know them better and build trust in their services.</p>
                            <br /><p className="end_points">{AgentVideoEndpoints} {AgentVideoEndpoints == 1 ? "Credit" : "Credits"} / 1 Video</p>

                        </a>
                    </div>
                </div>
                <div className="col-md-3 mb-2 ">
                    <div className="card">
                        <a href={`/generate/agent-profile-video-post`} className={`card-body generate-card generate-card-height ${storedPlatforms[0].generate.includes('all') || storedPlatforms[0].generate.includes('Agent Video') ? "" : "disabled"}`}>
                            <div className="rounded backg-marron">
                                <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M21.5867 2.66666H17V10.6667V11.6667V18.3333H29.3333V11.6667V10.6667V10.4133C29.3333 5.55999 26.44 2.66666 21.5867 2.66666Z" fill="#C29157" />
                                    <path d="M2.66699 13.6667V20.3333V21V21.5867C2.66699 26.44 5.56033 29.3333 10.4137 29.3333H15.0003V21V20.3333V13.6667H2.66699Z" fill="#C29157" />
                                    <path d="M15.0003 2.66666V11.6667H2.66699V10.4133C2.66699 5.55999 5.56033 2.66666 10.4137 2.66666H15.0003Z" fill="#C29157" />
                                    <path d="M29.3333 20.3333V21.5867C29.3333 26.44 26.44 29.3333 21.5867 29.3333H17V20.3333H29.3333Z" fill="#C29157" />
                                </svg>
                            </div>
                            <h2>
                                {storedPlatforms[0].generate.includes('all') || storedPlatforms[0].generate.includes('Agent Video') ? "Agent Profile Video (Post) " : "Agent Profile Video Post (Coming Soon) "}
                                <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M7.79664 18.0863C7.63039 18.0863 7.46414 18.025 7.33289 17.8938C7.07914 17.64 7.07914 17.22 7.33289 16.9663L13.0379 11.2613C13.4579 10.8413 13.4579 10.1588 13.0379 9.73877L7.33289 4.03377C7.07914 3.78002 7.07914 3.36002 7.33289 3.10627C7.58664 2.85252 8.00664 2.85252 8.26039 3.10627L13.9654 8.81127C14.4116 9.25752 14.6654 9.86127 14.6654 10.5C14.6654 11.1388 14.4204 11.7425 13.9654 12.1888L8.26039 17.8938C8.12914 18.0163 7.96289 18.0863 7.79664 18.0863Z" fill="#282828" />
                                </svg>
                            </h2>
                            <p>Craft professional videos to introduce and highlight real estate agents, helping clients get to know them better and build trust in their services.</p>
                            <br /><p className="end_points">{AgentVideoEndpoints} {AgentVideoEndpoints == 1 ? "Credit" : "Credits"} / 1 Video</p>
                        </a>
                    </div>
                </div>

            </div>
        </div>
    )

}
export default GenerateMain;