import React from "react";
import { CastImage } from "../Services/Generate";

const DownloadImage: React.FunctionComponent<{ image: any, isDisabledReplace: boolean }> = (props) => {

    const handleDownload = async (ext: any) => {
        CastImage({ image: props.image, ext: ext }).then(async (resp) => {
            try {
                const response = await fetch(resp);
                const blob = await response.blob();
                const url = window.URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.href = url;
                a.download=`image.${ext}`
                a.click();
                window.URL.revokeObjectURL(url);

            } catch (error) {
                console.error('Error downloading image:', error);
            }
        })
    }
    return (
        <>
            <button type="button" data-bs-toggle="dropdown" className={`btn mb-2 button-1 ${props.isDisabledReplace ? '' : 'disabled'}`} >
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M12.5003 18.9584H7.50033C2.97533 18.9584 1.04199 17.0251 1.04199 12.5001V7.50008C1.04199 2.97508 2.97533 1.04175 7.50033 1.04175H12.5003C17.0253 1.04175 18.9587 2.97508 18.9587 7.50008V12.5001C18.9587 17.0251 17.0253 18.9584 12.5003 18.9584ZM7.50033 2.29175C3.65866 2.29175 2.29199 3.65841 2.29199 7.50008V12.5001C2.29199 16.3417 3.65866 17.7084 7.50033 17.7084H12.5003C16.342 17.7084 17.7087 16.3417 17.7087 12.5001V7.50008C17.7087 3.65841 16.342 2.29175 12.5003 2.29175H7.50033Z" fill="#716BE3" />
                    <path d="M9.99987 12.7167C9.84154 12.7167 9.6832 12.6583 9.5582 12.5333L7.0582 10.0333C6.81654 9.79166 6.81654 9.39167 7.0582 9.15C7.29987 8.90833 7.69987 8.90833 7.94153 9.15L9.99987 11.2083L12.0582 9.15C12.2999 8.90833 12.6999 8.90833 12.9415 9.15C13.1832 9.39167 13.1832 9.79166 12.9415 10.0333L10.4415 12.5333C10.3165 12.6583 10.1582 12.7167 9.99987 12.7167Z" fill="#716BE3" />
                    <path d="M10 12.7167C9.65833 12.7167 9.375 12.4334 9.375 12.0917V5.42505C9.375 5.08338 9.65833 4.80005 10 4.80005C10.3417 4.80005 10.625 5.08338 10.625 5.42505V12.0917C10.625 12.4417 10.3417 12.7167 10 12.7167Z" fill="#716BE3" />
                    <path d="M9.99971 15.1916C8.24138 15.1916 6.47471 14.9083 4.79971 14.35C4.47471 14.2416 4.29971 13.8833 4.40804 13.5583C4.51638 13.2333 4.86637 13.05 5.19971 13.1666C8.29971 14.2 11.708 14.2 14.808 13.1666C15.133 13.0583 15.4914 13.2333 15.5997 13.5583C15.708 13.8833 15.533 14.2416 15.208 14.35C13.5247 14.9166 11.758 15.1916 9.99971 15.1916Z" fill="#716BE3" />
                </svg>
                تحميل
            </button>
            <ul className="dropdown-menu">
                <li>
                    <a className="dropdown-item" onClick={() => handleDownload("webp")}>
                        <span className="align-middle">Webp</span>
                    </a>
                </li>
                <li>
                    <a className="dropdown-item" onClick={() => handleDownload("png")}>
                        <span className="align-middle">PNG</span>
                    </a>
                </li>
                <li>
                    <a className="dropdown-item" onClick={() => handleDownload("jpg")}>
                        <span className="align-middle">JPG</span>
                    </a>
                </li>
            </ul>
        </>
    )
}
export default DownloadImage;